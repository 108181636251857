<template>
  <audit-data-list :omit="arOmits" :value="obAuditData" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import AuditDataList from "@/modules/audits/components/AuditDataList.vue";
import { map } from "lodash";
import type { AuditItem } from "@/modules/audits/mixins/AuditsMixin";

@Component({
  components: { AuditDataList },
})
export default class AuditOfferItem extends Vue {
  @Prop(Array) readonly value!: AuditItem[];

  get obAuditData() {
    return map(this.value, (obItem) => {
      switch (obItem.key) {
        case "price_list_id":
          obItem.label = "price.list";
          break;
        case "currency_id":
          obItem.label = "currency";
          break;
        case "product_id":
          obItem.label = "product";
          break;
      }

      return obItem;
    });
  }

  get arOmits() {
    return [
      "external_id",
      "quantity_in_unit",
      "measure_of_unit_id",
      "width",
      "length",
      "weight",
      "height",
      "measure_id",
    ];
  }
}
</script>
