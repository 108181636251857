<template>
  <v-navigation-drawer
    app
    fixed
    temporary
    stateless
    disable-route-watcher
    :right="right"
    :width="drawerWidth"
    @input="onDrawerChange"
    v-model="drawer"
    class="gw-sheet-drawer"
  >
    <slot></slot>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";

@Component
export default class SheetDrawer extends Vue {
  @PropSync("value", { type: Boolean }) drawer!: boolean;
  @Prop({ default: true }) readonly right!: boolean;
  @Prop({ default: 0 }) readonly margin!: number;
  @Prop({ default: 100 }) readonly xs!: number;
  @Prop({ default: 100 }) readonly sm!: number;
  @Prop({ default: 100 }) readonly md!: number;
  @Prop({ default: 85 }) readonly lg!: number;
  @Prop({ default: 75 }) readonly xl!: number;

  onDrawerChange(value: boolean) {
    if (!value) {
      this.$emit("close", true);
    }
  }

  close() {
    this.$emit("input", false);
  }

  viewportSize() {
    return window.innerWidth || document.documentElement.clientWidth;
  }

  percent(iValue: number, iPercent: number): number {
    return iPercent < 100 ? (iPercent / 100) * iValue : iValue;
  }

  get drawerWidth() {
    let iSize = this.viewportSize() - this.margin;
    iSize = this.percent(
      iSize,
      this.$_.get(this, this.$vuetify.breakpoint.name)
    );

    return iSize;
  }
}
</script>
