<template>
  <audit-data-list :omit="arOmits" :value="obAuditData" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import AuditDataList from "@/modules/audits/components/AuditDataList.vue";
import type { AuditItem } from "@/modules/audits/mixins/AuditsMixin";

@Component({
  components: { AuditDataList },
})
export default class AuditPriceItem extends Vue {
  @Prop(Array) readonly value!: AuditItem[];

  get obAuditData() {
    return this.value;
  }

  get arOmits() {
    return ["old_price", "item_id", "item_type", "price_type_id"];
  }
}
</script>
