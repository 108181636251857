<template>
  <audit-data-list :omit="arOmits" :value="obAuditData" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import AuditDataList from "@/modules/audits/components/AuditDataList.vue";
import { map } from "lodash";
import type { AuditItem } from "@/modules/audits/mixins/AuditsMixin";

@Component({
  components: { AuditDataList },
})
export default class AuditCategoryItem extends Vue {
  @Prop(Array) readonly value!: AuditItem[];

  get obAuditData() {
    return map(this.value, (obItem) => {
      switch (obItem.key) {
        case "preview_text":
          obItem.label = "description";
          break;
      }

      return obItem;
    });
  }

  get arOmits() {
    return [
      "external_id",
      "parent_id",
      "nest_left",
      "nest_right",
      "nest_depth",
      "slug",
    ];
  }
}
</script>
